import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
const swiper = new Swiper('.request__swiper', {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  spaceBetween: 42,
  loop: true,
  breakpoints: {
    320: { slidesPerView: 1, spaceBetween: 25},
    556: { slidesPerView: 2, spaceBetween: 25 },
    800: { slidesPerView: 3, slidesPerGroup: 1 },
    1100: { slidesPerView: 4, slidesPerGroup: 1},
  },
});

var init = false;
var swiper2;
function swiperCard() {
  if (window.innerWidth <= 650) {
    if (!init) {
      init = true;
      swiper2 = new Swiper(".project__swiper", {
        // direction: "horizontal",
        // slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 5,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    }
  } else if (init) {
    swiper2.destroy();
    init = false;
  }
}
swiperCard();
window.addEventListener("resize", swiperCard);
